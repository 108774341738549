var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('Menu',{staticStyle:{"position":"fixed","height":"50px","z-index":"1","margin-top":"-12px","background":"#DBC7FF","left":"0","right":"0"},attrs:{"freezer":true,"food":true}}),_c('div',{staticClass:"pb-5 px-15",staticStyle:{"margin-top":"40px","background":"#F4F9FF"}},[_c('v-card-title',[_c('label',{staticClass:"item_title"},[_vm._v("Freezers and Markets")])]),_c('v-card-subtitle',{staticClass:"mt-2"},[_c('label',{staticClass:"item_description"},[_vm._v(" Please manage your freezer managers and markets infomration: ")])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.freezers,"items-per-page":15,"loading":_vm.loading,"custom-sort":_vm.customSort,"search":_vm.search,"custom-filter":_vm.customFilter},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"elevation-6 my-3 ml-5"},[_c('v-img',{attrs:{"src":item.image ? item.image : _vm.placeholderImage}})],1)]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.organization))}})]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.getFiltered(
              item.first_name + ' ' + (item.last_name ? item.last_name : '')
            )
          )}})]}},{key:"item.email",fn:function(ref){
          var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.email))}})]}},{key:"item.phone",fn:function(ref){
          var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(_vm.formatPhoneNumber(item.phone)))}})]}},{key:"item.type",fn:function(ref){
          var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.type))}})]}},{key:"item.service",fn:function(ref){
          var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.service))}})]}},{key:"item.address",fn:function(ref){
          var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(item.address))}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onEditStocks(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-store")])],1)]}}],null,true)},[_vm._v(" Edit Stocks ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onEditFreezer(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Edit ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToChat(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-message-outline")])],1)]}}],null,true)},[_vm._v(" Chat ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onDeleteFreezer(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_vm._v(" Delete ")])],1)]}},{key:"item.balance",fn:function(ref){
          var item = ref.item;
return [(item.type == 'Freezer')?_c('div',[_vm._v(" "+_vm._s(item.balance.length == 0 ? 0 : item.balance[0].amount)+" ")]):_vm._e()]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this freezer? This action can not be undone.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = !_vm.deleteDialog}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":_vm.deleteAction}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1),(_vm.stocksDialog)?_c('stocks-dialog',{attrs:{"dialog":_vm.stocksDialog,"item":_vm.selected,"onCloseDialog":_vm.onCloseDialog}}):_vm._e(),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }