<template>
  <v-col align="bottom" justify="bottom" class="d-flex flex-row-reverse">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      class="modal-container-stock"
    >
      <v-card v-if="freezer">
        <v-card-title
          style="display:flex; flex-direction: row; justify-content:space-between;"
        >
          <span class="headline">Edit {{ freezer.type }} stocks</span>
          <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text
          class="pb-0"
          style="align-items: center; display: flex; flex-direction: row;"
          v-if="freezer"
        >
          <editable-avatar
            :image="freezer.image"
            :size="60"
            :editable="false"
          />
          <div class="d-flex flex-column ml-5">
            <div style="font-size: 18px;">
              {{ freezer.first_name + " " + freezer.last_name }}
            </div>
            <div>
              {{ freezer.type }}
            </div>
            <div>
              {{
                freezer.address +
                  (freezer.address2 ? ", " + freezer.address2 : "")
              }}
            </div>
          </div>
        </v-card-text>
        <v-card-text class="mt-5 mb-0 pb-0">
          <v-row>
            <v-col cols="4">
              <v-autocomplete
                :disabled="loading"
                :items="foods"
                dense
                background-color="white"
                label="Select Food"
                :filter="searchFilter"
                @change="onSelectedFood"
                :error-messages="searchError"
                class="notranslate"
                v-model="newFood"
              >
                <template v-slot:selection="data">
                  {{ data.item.name }}
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <editable-avatar
                      :image="data.item.image"
                      :size="40"
                      :editable="false"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name" />
                    <v-list-item-subtitle
                      v-html="
                        (data.item.serving_size ? data.item.serving_size : '') +
                          ' ' +
                          data.item.unit
                      "
                    />
                    <v-list-item-subtitle
                      v-html="data.item.meals + ' Serving'"
                    />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field type="number" v-model="newAmount" dense />
            </v-col>
            <v-col cols="4">
              <v-btn @click="onAddBalance" small>
                {{ isEditing ? "Edit Item" : "Add Item" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="mt-0 pt-0 modal-content-stock">
          <v-data-table :items="balances" :headers="headers" :loading="loading">
            <template v-slot:[`item.image`]="{ item }">
              <div
                class="ma-3"
                style="width: 140px; max-width: 140px;"
                v-if="item.food"
              >
                <v-img
                  :src="item.food.image ? item.food.image : placeholderImage"
                  :width="140"
                  :height="100"
                />
              </div>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{ item.food ? item.food.name : "" }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div style="width: 120px;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="onEditBalance(item)"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                  </template>
                  Edit
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="onDeleteFood(item)"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </template>
                  Delete
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "../core/EditableAvatar.vue";

export default {
  components: {
    EditableAvatar,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onCloseDialog: {
      type: Function,
    },
    item: Object,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      freezer: null,
      error: null,
      loading: false,
      newFood: null,
      searchError: null,
      newAmount: null,
      isEditing: false,
      selected: null,
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount" },
        { text: "Actions", value: "actions" },
      ],
      placeholderImage: require("@/assets/default_image.png"),
    };
  },
  methods: {
    ...mapActions({
      fetchAllFreezerBalances: "freezer/fetchAllFreezerBalances",
      fetchAllFoods: "food/fetchAllFoods",
      addBalance: "freezer/addBalance",
      updateBalance: "freezer/editBalance",
      deleteBalance: "freezer/deleteBalance",
    }),
    onClose() {
      this.loading = false;
      this.error = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
    onSelectedFood(item) {
      this.newFood = item;
    },
    searchFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      if (
        Object.keys(item).some(
          (k) => item[k] && `${item[k]}`.toLowerCase().includes(searchText)
        )
      ) {
        return true;
      }
      if (item.name.includes(searchText)) {
        return true;
      }
      return false;
    },
    onAddBalance() {
      if (!this.isEditing) {
        this.loading = true;
        this.addBalance({
          food_id: this.newFood._id,
          amount: this.newAmount,
          freezer_id: this.freezer._id,
        })
          .then(() => {
            this.loading = false;
            this.newFood = null;
            this.newAmount = null;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response.data.message);
          });
      } else {
        this.loading = true;
        this.updateBalance({
          _id: this.selected._id,
          amount: this.newAmount,
        })
          .then(() => {
            this.loading = false;
            this.newFood = null;
            this.newAmount = null;
            this.selected = null;
            this.isEditing = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response.data.message);
          });
      }
    },
    onEditBalance(item) {
      for (var i = 0; i < this.foods.length; i++) {
        if (this.foods[i]._id == item.food._id) {
          this.newFood = this.foods[i];
          break;
        }
      }
      this.selected = item;
      this.newAmount = item.amount;
      this.isEditing = 1;
    },
    onDeleteFood(item) {
      this.loading = true;
      this.deleteBalance({ _id: item._id })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
  },
  computed: {
    ...mapGetters({
      balances: "freezer/getAllBalances",
      foods: "food/getAllFoods",
    }),
  },
  mounted() {
    if (this.item) {
      this.freezer = this.item;
      this.loading = false;
      this.fetchAllFreezerBalances({ _id: this.item._id })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
      this.fetchAllFoods().catch((error) => {
        console.log(error.response.data.message);
      });
    }
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.notranslate {
  transform: none !important;
}
.modal-container-stock {
  max-height: 80vh;
  overflow: hidden;
}
.modal-content-stock {
  overflow: auto;
  max-height: calc(80vh - 200px);
}
</style>
