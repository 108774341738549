<template>
  <v-container fluid>
    <Menu
      style="position:fixed; height: 50px; z-index: 1;margin-top:-12px; background: #DBC7FF; left: 0; right: 0;"
      :freezer="true"
      :food="true"
    />
    <div class="pb-5 px-15" style="margin-top: 40px; background: #F4F9FF;">
      <v-card-title>
        <label class="item_title">Freezers and Markets</label>
      </v-card-title>
      <v-card-subtitle class="mt-2">
        <label class="item_description">
          Please manage your freezer managers and markets infomration:
        </label>
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="freezers"
        :items-per-page="15"
        :loading="loading"
        class="elevation-0"
        :custom-sort="customSort"
        :search="search"
        :custom-filter="customFilter"
      >
        <template v-slot:[`item.image`]="{ item }">
          <v-avatar class="elevation-6 my-3 ml-5">
            <v-img :src="item.image ? item.image : placeholderImage" />
          </v-avatar>
        </template>
        <template v-slot:[`item.organization`]="{ item }">
          <div v-html="getFiltered(item.organization)" />
        </template>
        <template v-slot:[`item.full_name`]="{ item }">
          <div
            v-html="
              getFiltered(
                item.first_name + ' ' + (item.last_name ? item.last_name : '')
              )
            "
          />
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <div v-html="getFiltered(item.email)" />
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <div v-html="getFiltered(formatPhoneNumber(item.phone))" />
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <div v-html="getFiltered(item.type)" />
        </template>
        <template v-slot:[`item.service`]="{ item }">
          <div v-html="getFiltered(item.service)" />
        </template>
        <template v-slot:[`item.address`]="{ item }">
          <div v-html="getFiltered(item.address)" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="onEditStocks(item)" v-bind="attrs" v-on="on"
                  ><v-icon>mdi-store</v-icon></v-btn
                >
              </template>
              Edit Stocks
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="onEditFreezer(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </template>
              Edit
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="goToChat(item)" v-bind="attrs" v-on="on"
                  ><v-icon>mdi-message-outline</v-icon></v-btn
                >
              </template>
              Chat
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="onDeleteFreezer(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </template>
              Delete
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <div v-if="item.type == 'Freezer'">
            {{ item.balance.length == 0 ? 0 : item.balance[0].amount }}
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this freezer? This action can not be
          undone.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = !deleteDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="deleteAction"
            :loading="loading"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <stocks-dialog
      :dialog="stocksDialog"
      :item="selected"
      :onCloseDialog="onCloseDialog"
      v-if="stocksDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import Menu from "../../components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
import StocksDialog from "../../components/admin/StocksDialog.vue";
export default {
  components: { Menu, StocksDialog },
  data() {
    return {
      isSelectingPhoto: false,
      loading: false,
      errorMessage: null,
      snackbar: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Organization", value: "organization" },
        { text: "Manager", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Type", value: "type" },
        { text: "Address", value: "address" },
        { text: "Actions", value: "actions" },
      ],
      selected: -1,
      placeholderImage: require("@/assets/default.jpg"),
      profile: { email: "" },
      selectedFile: null,
      stocksDialog: false,
      deleteDialog: false,
      search: "",
      freezers: [],
    };
  },
  computed: {
    ...mapGetters({
      allFreezers: "freezer/getAllFreezers",
      keyword: "auth/getKeyword",
    }),
  },
  methods: {
    ...mapActions("freezer", {
      fetchAllFreezers: "fetchAllFreezers",
      addFreezer: "addFreezer",
      editFreezer: "editFreezer",
      deleteFreezer: "deleteFreezer",
      setBalance: "setBalance",
    }),
    onImageSelected(file) {
      this.selectedFile = file;
    },
    onChange() {
      this.errorMessage = null;
    },
    onSelectFreezer(item) {
      console.log(item);
    },
    onSave() {
      this.loading = true;
      var formData = new FormData();
      console.log(this.profile);
      Object.keys(this.profile).map((key) => {
        if (this.profile[key]) formData.append(key, this.profile[key]);
      });
      if (this.selectedFile) formData.append("image", this.selectedFile);

      this.editFreezer(formData)
        .then(() => {
          this.loading = false;
          this.selected = 0;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    onEditFreezer(item) {
      this.$router.push({ name: "freezer-details", params: { id: item._id } });
    },
    onEditStocks(item) {
      this.selected = item;
      this.stocksDialog = true;
    },
    onDeleteFreezer(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    onIntake() {
      this.$router.push({ name: "intake", params: { id: this.profile._id } });
    },
    onDelete() {
      this.deleteDialog = true;
    },
    deleteAction() {
      this.loading = true;
      this.deleteFreezer(this.selected)
        .then(() => {
          this.loading = false;
          this.selected--;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    customSort(items, index, isDesc) {
      if (index.length == 0 || index[0] === "full_name") {
        items.sort((a, b) => {
          var order = 0;
          var aStr = `${a["first_name"]} ${a["last_name"]}`;
          var bStr = `${b["first_name"]} ${b["last_name"]}`;

          if (aStr < bStr) order = -1;
          else if (aStr > bStr) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      } else {
        items.sort((a, b) => {
          var order = 0;
          if (a[index] < b[index]) order = -1;
          else if (a[index] > b[index]) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      }

      return items;
    },
    getFiltered(words) {
      if (!words) return "";
      var filterd = this.$options.filters.highlight(words, this.keyword);
      return filterd;
    },
    customFilter(value, search, item) {
      var searchFilter = false;
      if (this.keyword) {
        if (
          (`${item.first_name} ${item.last_name}` &&
            `${item.first_name} ${item.last_name}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            item.phone.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            this.formatPhoneNumber(item.phone)
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.address &&
            item.address.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.address2 &&
            item.address2.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.type &&
            item.type.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.email &&
            item.email.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.service &&
            item.service.toUpperCase().includes(this.keyword.toUpperCase()))
        ) {
          searchFilter = true;
        }
      } else {
        searchFilter = true;
      }
      return searchFilter;
    },
    goToChat(item) {
      this.$router.push({
        name: "chat-details",
        params: { id: "inbox" },
        query: {
          _id: item._id,
          user_type: "Freezer",
        },
      });
    },
    onCloseDialog() {
      this.stocksDialog = false;
    },
  },
  created() {
    this.loading = true;
    this.fetchAllFreezers()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
  watch: {
    selected(newValue) {
      if (this.freezers.length > newValue) {
        this.profile = this.freezers[newValue];
        if (this.profile.balance.length != 0) {
          this.profile.balance = this.profile.balance.map((b) => {
            return { ...b, editing: false };
          });
        }
      }
    },
    keyword(newValue) {
      this.search = newValue;
    },
    allFreezers(newValue) {
      if (this.$route.query.new) {
        this.freezers = newValue.filter((item) => {
          var startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
          var creatTime = new Date(item.createdAt);
          if (creatTime < startDate) {
            return false;
          }
          return true;
        });
      } else {
        this.freezers = newValue;
      }
    },
  },
};
</script>
<style scoped>
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-left: 5px;
  padding-top: 10px;
}
.item_description {
  color: #482684;
  /* font-family: 'Poppins-Bold'; */
  font-size: 18px;
  padding-left: 7px;
}
.freezer_title {
  color: #f0a000;
  font-family: "Poppins-SemiBold";
  font-size: 25px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.profile {
  overflow: visible;
}
.request_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.request_description_item {
  color: #482684;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
/* .v-text-field >>> input {
    font-size: 10px;
  } */
.v-text-field >>> .v-text-field__details {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}
</style>
